import { _get, _save } from '@/communication/api'

const URL_BASE_STATISTICS_LOCATIONS = 'app/api/v1/statistics-locations'
const URL_BASE_STATISTICS = 'app/api/v1/statistics-location'
const URL_BASE_STATISTICS_BY_TYPE = 'app/api/v1/statistics-location-type'

/**
 * Get a list of locations with data on a date range
 * @param {String} sensor - The sensor serial number for identify the site (required)
 * @return {Promise} - A promise with an array of locations
 */
export function getStatisticsLocations ( sensor ) {
  const url = `${URL_BASE_STATISTICS_LOCATIONS}?sensor=${sensor}`
  return _get(url)
}

/**
 * Get a map of identifications by hour on each location
 * @param {Object} params - The object with the url params
 * @param {String} sensor - The sensor serial number for identify the site (required)
 * @param {String} dateInit - The date from when filter the data (required)
 * @param {String} dateEnd - The date until when filter the data (required)
 * @param {String} location - The location id for filter the data
 * @return {Promise} - A promise with an array of statistics for each location
 */
export function getStatistics ( params ) {
  const location = params.location ? `&location=${params.location}` : ''

  const url = `${URL_BASE_STATISTICS}?sensor=${params.sensor}&dateInit=${params.dateInit}&dateEnd=${params.dateEnd}${location}`
  return _get(url)
}

/**
 * Get a map of identifications by hour on grouped by location types
 * @param {Object} params - The Object with the url params
 * @param {String} sensor - The sensor serial number for identify the site (required)
 * @param {String} dateInit - The date from when filter the data (required)
 * @param {String} dateEnd - The date until when filter the data (required)
 * @param {String} locationType - The location type id for filter the data
 * @return {Promise} - A promise with an array of statistics for each location type
 * @returns 
 */
export function getStatisticsByType ( params ) {
  const locationType = params.locationType ? `&location=${params.locationType}` : ''

  const url =`${URL_BASE_STATISTICS_BY_TYPE}?sensor=${params.sensor}&dateInit=${params.dateInit}&dateEnd=${params.dateEnd}${locationType}`
  return _get(url)
}
