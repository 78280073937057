import { createStore } from 'vuex'
import location from './location.js'
import patient from './patient'
import sensor from './sensor'
import staff from './staff'
import statistic from './statistic'

const store = createStore({
  modules: {
    location,
    patient,
    sensor,
    staff,
    statistic
  }
})

export default store
