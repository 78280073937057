import { CALENDAR_MODES } from '@/constants/constants'
import {
  getStatistics,
  getStatisticsByType,
  getStatisticsLocations
} from '@/communication/bundles_min'

const stateObj = {
  calendarMode: null,
  filterDateInit: null,
  filterDateEnd: null,
  filterLocation: null,
  filterLocationType: null,
  isLoadingData: false,
  statisticsData: [],
  statisticsLocations: []
}

const getters = {
  getCalendarModeSelected (state) {
    return state.calendarMode
  },
  getIsLoadingData (state) {
    return state.isLoadingData
  },
  getSelectedDate (state) {
    switch (state.calendarMode) {
      case CALENDAR_MODES.DAY.mode:
        return new Date(state.filterDateInit).toLocaleDateString()
      case CALENDAR_MODES.MONTH.mode:
        return new Date(state.filterDateInit).toLocaleString('default', { month: 'long' })
      case CALENDAR_MODES.YEAR.mode:
        return new Date(state.filterDateInit).getFullYear()
      default:
        return ''
    }
  },
  getFilterDateInit (state) {
    return state.filterDateInit
  },
  getFilterDateEnd (state) {
    return state.filterDateEnd
  },
  getFilterLocation (state) {
    return state.filterLocation
  },
  getFilterLocationType (state) {
    return state.filterLocationType
  },
  getStatisticsLocations (state) {
    const statisticsLocationsList = [{ id: 0, name: 'General', locationTypeId: null }]

    for (const [key, value] of Object.entries(state.statisticsLocations)) {
      const childsList = []
      value.map(i => childsList.push({
        id: i.id,
        name: i.name
      }))
      statisticsLocationsList.push({
        id: key,
        name: value[0].locationType.name,
        locationTypeId: value[0].locationType.id,
        childs: childsList
      })
    }
    return statisticsLocationsList
  },
  getStatisticsData (state) {
    return state.statisticsData
  }
}

const mutations = {
  setCalendarMode (state, value) {
    state.calendarMode = value
  },
  setIsLoadingData (state, value) {
    state.isLoadingData = value
  },
  setFilterDateInit (state, value) {
    state.filterDateInit = value
  },
  setFilterDateEnd (state, value) {
    state.filterDateEnd = value
  },
  setFilterLocation (state, value) {
    state.filterLocation = value
  },
  setFilterLocationType (state, value) {
    state.filterLocationType = value
  },
  setStatisticsLocations (state, value) {
    state.statisticsLocations = value
  },
  setStatisticsData (state, value) {
    state.statisticsData = value
  }
}

const actions = {
  fetchStatisticsLocations ({ commit }, payload) {
    getStatisticsLocations(payload)
      .then(response => {
        commit('setStatisticsLocations', response)
        return response
      })
      .catch(error => {
        throw Error(error)
      })
  },
  fetchStatistics ({ commit }, payload) {
    commit('setIsLoadingData', true)
    getStatistics(payload)
      .then(response => {
        commit('setIsLoadingData', false)
        commit('setStatisticsData', response)
        return response
      })
      .catch(error => {
        commit('setIsLoadingData', false)
        throw Error(error)
      })
  },
  fetchStatisticsByType ({ commit }, payload) {
    commit('setIsLoadingData', true)
    getStatisticsByType(payload)
      .then(response => {
        commit('setIsLoadingData', false)
        commit('setStatisticsData', response)
        return response
      })
      .catch(error => {
        commit('setIsLoadingData', false)
        throw Error(error)
      })
  },
  updateCalendarMode ({ commit }, payload) {
    commit('setCalendarMode', payload)
  },
  updateDateFilters ({ commit }, payload) {
    commit('setFilterDateInit', payload[0])
    commit('setFilterDateEnd', payload[1])
  },
  updateLocationFilter ({ commit }, payload) {
    commit('setFilterLocation', payload)
  },
  updateLocationTypeFilter ({ commit }, payload) {
    commit('setFilterLocationType', payload)
  }
}

export default {
  actions,
  getters,
  mutations,
  state: stateObj
}
