// Error codes
export const ERR_CODE_PATIENT_ALREADY_REGISTERED = 409
export const ERR_CODE_SENSOR_SN_NOT_REGISTERED = 424

export const HANDS = {
  LEFT: { token: 'main.left_hand', index: 0 },
  RIGHT: { token: 'main.right_hand', index: 1 }
}

export const FINGERS = {
  THUMB: { token: 'main.thumb', index: 0 },
  INDEXFINGER: { token: 'main.indexfinger', index: 1 },
  MIDDLEFINGER: { token: 'main.middlefinger', index: 2 },
  RINGFINGER: { token: 'main.ringfinger', index: 3 },
  PINKY: { token: 'main.pinky', index: 4 }
}

export const FINGERS_LEFT_HAND = {
  THUMB: { token: 'main.thumb', index: 0 },
  INDEXFINGER: { token: 'main.indexfinger', index: 1 },
  MIDDLEFINGER: { token: 'main.middlefinger', index: 2 },
  RINGFINGER: { token: 'main.ringfinger', index: 3 },
  PINKY: { token: 'main.pinky', index: 4 }
}

export const FINGERS_RIGHT_HAND = {
  PINKY: { token: 'main.pinky', index: 5 },
  RINGFINGER: { token: 'main.ringfinger', index: 6 },
  MIDDLEFINGER: { token: 'main.middlefinger', index: 7 },
  INDEXFINGER: { token: 'main.indexfinger', index: 8 },
  THUMB: { token: 'main.thumb', index: 9 }
}

export const LAST_INDEX_RIGHT_HAND = 9

// Calendar modes
export const CALENDAR_MODES = {
  DAY: { mode: 'day', text: 'calendar.modes.day' },
  MONTH: { mode: 'month', text: 'calendar.modes.month' },
  YEAR: { mode: 'year', text: 'calendar.modes.year' }
}

export const ACCESS_TOKEN = 'AccessToken'
export const FINGERS_DATA = 'FingersData'
export const HHID = 'HHID'
export const IS_NEW_PATIENT = 'isNewPatient'
export const TOKEN_KEY = 'token'
export const USER_DATA = 'UserData'
export const HTTP_ERROR_UNAUTHORIZED = 401
