import {
  STATISTICS_DETAILS_PAGE_ROUTE_NAME,
  STATISTICS_OVERVIEW_PAGE_ROUTE_NAME,
  STATISTICS_PAGE_ROUTE_NAME
} from './routes'

const StatisticsDetailsPage = () => import('@/pages/statistics/StatisticsDetailsPage')
const StatisticsOverviewPage = () => import('@/pages/statistics/StatisticsOverviewPage')
const StatisticsPage = () => import('@/pages/statistics/StatisticsPage')

export default [
  {
    path: '/statistics',
    name: STATISTICS_PAGE_ROUTE_NAME,
    component: StatisticsPage,
    children: [
      {
        path: 'statistics-overview/',
        name: STATISTICS_OVERVIEW_PAGE_ROUTE_NAME,
        component: StatisticsOverviewPage
      },
      {
        path: 'statistics-details/:phase',
        name: STATISTICS_DETAILS_PAGE_ROUTE_NAME,
        component: StatisticsDetailsPage
      }
    ]
  }
]
